<template>
  <div>
    <div v-if="isLoading" class="spinner-container">
      <div class="spinner"></div>
    </div>
    <div class="main-content" :class="{ 'is-hidden': isLoading }">
      <div class="spark-section-2 spark-dark-hero-background-image">
        <div class="spark-container-2 w-container">
          <div class="spark-flex-row spark-5-spacing"></div>
          <div class="spark-max-width-730">
            <h1>Our latest job posts</h1>
            <p class="spark-hero-sub-paragraph">
              Find the perfect job match right here.
            </p>
            <div class="header_index spark-left-aligned"></div>
          </div>
        </div>
      </div>
      <div class="section padding">
        <div class="container spacing_zero less-padding w-container">
          <div class="filters-container">
            <div class="search-container">
              <input
                v-model="searchQueryRaw"
                type="text"
                placeholder="Search Jobs"
                class="search-input"
              />
              <span class="search-icon">
                <span v-if="isSearching" class="searchSpinner"></span>
                <span v-else>🔍</span>
              </span>
            </div>
            <div class="filters">
              <select v-model="selectedLocation" class="filter-dropdown">
                <option value="">All Regions</option>
                <option v-for="loc in uniqueLocations" :key="loc" :value="loc">
                  {{ loc }}
                </option>
              </select>

              <select v-model="selectedCategory" class="filter-dropdown">
                <option value="">All Categories</option>
                <option v-for="cat in uniqueCategories" :key="cat" :value="cat">
                  {{ cat }}
                </option>
              </select>

              <select v-model="selectedLevel" class="filter-dropdown">
                <option value="">All Levels</option>
                <option
                  v-for="level in uniqueLevels"
                  :key="level"
                  :value="level"
                >
                  {{ level }}
                </option>
              </select>
            </div>
          </div>
          <div class="horizontal-jobs-wrapper">
            <div class="w-dyn-list">
              <div role="list" class="horizontal-jobs-grid w-dyn-items">
                <div
                  v-for="job in combinedFilteredJobs"
                  :key="job.id"
                  class="job-card-item w-dyn-item job-card horizontal"
                  :data-job-id="job.id"
                >
                  <router-link
                    :to="'/jobs/' + job.id"
                    class="job-card-item w-dyn-item job-card-routerLink horizontal"
                  >
                    <div class="job-card-company-wrap square w-inline-block">
                      <div class="company-large-icon-wrap">
                        <img
                          :src="job.properties.companyImageLink.url"
                          alt="Company Logo"
                          class="company-icon-job"
                        />
                      </div>
                    </div>
                    <div class="job-card-content horizontal">
                      <div>
                        <a
                          :href="job.jobLink"
                          class="job-card-link w-inline-block"
                        >
                          <h3 class="heading-11">
                            {{ job.properties.job_title.title[0].text.content }}
                          </h3>
                        </a>
                        <div class="text-block-26">{{ job.description }}</div>
                      </div>
                      <div class="job-tags-wrap">
                        <a :href="job.employmentTypeLink" class="job-tag">{{
                          job.properties.employment_type.select.name
                        }}</a>
                        <a :href="job.levelLink" class="job-tag">{{
                          job.properties.level.select.name
                        }}</a>
                        <a :href="job.locationLink" class="job-tag">{{
                          job.properties.location.rich_text[0].text.content
                        }}</a>
                      </div>
                      <div class="job-card-head">
                        <a :href="job.categoryLink" class="link">{{
                          job.properties.category.select.name
                        }}</a>
                        <div class="opacity-black">
                          {{
                            new Date(job.properties.created_time.created_time)
                              .toISOString()
                              .slice(0, 10)
                          }}
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import axios from "axios";
import debounce from "lodash.debounce";

export default {
  name: "JobList",
  setup() {
    const jobs = ref([]);
    const isLoading = ref(false);
    const selectedLocation = ref("");
    const selectedCategory = ref("");
    const selectedLevel = ref("");
    const searchQueryRaw = ref(""); // Direkte Eingabe
    const searchQuery = ref(""); // Entprellte Suche
    const isSearching = ref(false); // Ladezustand für die Suche

    // Debounced Search Query Update
    const updateSearchQuery = debounce(async (value) => {
      isSearching.value = true; // Ladezustand aktivieren
      await new Promise((resolve) => setTimeout(resolve, 300)); // Simulierte Latenz (optional)
      searchQuery.value = value;
      isSearching.value = false; // Ladezustand deaktivieren
    }, 300);

    // Watch für das rohe Suchfeld
    watch(searchQueryRaw, (newValue) => {
      updateSearchQuery(newValue);
    });

    const fetchJobs = async () => {
      isLoading.value = true;
      try {
        const response = await axios.get(
          "https://adan-secureserver.mainly-dev.de:3000/jobs"
        );
        jobs.value = response.data;
      } catch (error) {
        console.error("Fehler beim Abrufen der Jobdaten:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const combinedFilteredJobs = computed(() => {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Uhrzeit auf 00:00:00 setzen, um nur das Datum zu vergleichen

      // Überprüft, ob onlineUntil nicht in der Vergangenheit liegt
      const lowercasedQuery = searchQuery.value.toLowerCase();

      return jobs.value.filter((job) => {
        const onlineUntil = new Date(job.properties.onlineUntil.date.start); // Konvertiert `onlineUntil` in ein Date-Objekt
        const isFutureDate = onlineUntil >= currentDate; // Überprüft, ob `onlineUntil` in der Zukunft oder heute ist

        const matchesTitle =
          !searchQuery.value ||
          job.properties.job_title.title[0]?.text?.content
            .toLowerCase()
            .includes(lowercasedQuery);

        const matchesLocation =
          !selectedLocation.value ||
          job.properties.bundesland4api?.formula?.string ===
            selectedLocation.value;

        const matchesCategory =
          !selectedCategory.value ||
          job.properties.category?.select?.name === selectedCategory.value;

        const matchesLevel =
          !selectedLevel.value ||
          job.properties.level?.select?.name === selectedLevel.value;

        // Kombiniert alle Filterbedingungen
        return (
          isFutureDate && // Nur Jobs mit `onlineUntil` in der Zukunft
          matchesTitle && // Passend zum Suchbegriff
          matchesLocation && // Passend zur ausgewählten Region
          matchesCategory && // Passend zur ausgewählten Kategorie
          matchesLevel // Passend zur ausgewählten Ebene
        );
      });
    });

    // Tracking der Sichtbarkeit von Jobs
    const trackJobViewport = async (jobId) => {
      try {
        await axios.post(
          `https://adan-secureserver.mainly-dev.de:3000/jobs/${jobId}/viewport`
        );
      } catch (error) {
        console.error("Fehler beim Tracken des Job-Viewports:", error);
      }
    };

    // Beobachtung von Jobs für Viewport-Tracking
    const observer = ref(null);
    const observeJobs = () => {
      const options = {
        root: null, // bezieht sich auf den Viewport
        rootMargin: "0px",
        threshold: 0.1, // 10% des Elements sind im Viewport
      };

      observer.value = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Element ist im Viewport, führe Tracking aus
            trackJobViewport(entry.target.dataset.jobId);
            observer.unobserve(entry.target); // Stoppe die Beobachtung, um mehrfaches Zählen zu vermeiden
          }
        });
      }, options);

      // Füge jedes Job-Element zum Observer hinzu
      document.querySelectorAll(".job-card-item").forEach((el) => {
        observer.value.observe(el);
      });
    };

    onMounted(async () => {
      await fetchJobs();
      // Warte einen kurzen Moment, damit die DOM-Elemente geladen werden können
      setTimeout(observeJobs, 1000);
    });

    onUnmounted(() => {
      // Bereinige den Observer, wenn die Komponente zerstört wird
      if (observer.value) {
        observer.value.disconnect();
      }
    });

    const uniqueLocations = computed(() => {
      const locations = jobs.value
        .map((job) => job.properties.bundesland4api?.formula?.string)
        .filter((location) => location) // filter out undefined or null values
        .sort(); // sort the unique locations alphabetically
      return [...new Set(locations)];
    });

    const uniqueCategories = computed(() => {
      const categories = jobs.value
        .map((job) => job.properties.category?.select?.name)
        .filter((category) => category) // filter out undefined or null values
        .sort(); // sort the unique categories alphabetically
      return [...new Set(categories)];
    });

    const uniqueLevels = computed(() => {
      const levels = jobs.value
        .map((job) => job.properties.level?.select?.name)
        .filter((level) => level) // filter out undefined or null values
        .sort(); // sort the unique levels alphabetically
      return [...new Set(levels)];
    });

    return {
      isLoading,
      isSearching,
      searchQueryRaw,
      selectedLocation,
      selectedCategory,
      selectedLevel,
      combinedFilteredJobs,
      uniqueLocations,
      uniqueCategories,
      uniqueLevels,
    };
  },
};

// IM LIVEBETRIEB LOCALHOST WIEDER AUF
// https: // 172. 104. 134. 243 oder
// https:// adan- secureserver . mainly-dev.de
// ÄNDERN
</script>

<style scoped>
.filters-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px; /* Abstand zwischen Suchfeld und Filtern */
  margin-bottom: 20px; /* Abstand zu den restlichen Inhalten */
  width: 100%; /* Container passt sich an */
}

.search-container {
  width: 100%; /* Volle Breite für das Suchfeld */
  position: relative; /* Für die Platzierung des Icons */
}

.search-input {
  width: 100%; /* Volle Breite für das Eingabefeld */
  padding: 10px 40px 10px 15px; /* Platz für das Symbol */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Padding wird in die Breite eingerechnet */
}

.searchSpinner {
  display: inline-block;
  width: 16px; /* Breite des Spinners */
  height: 16px; /* Höhe des Spinners */
  border: 2px solid #ccc; /* Breite des Spinnerrands */
  border-top-color: #00aa96; /* Farbe des oberen Randes */
  border-radius: 50%;
  animation: spin 0.8s linear infinite; /* Schnelleres Drehen */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.search-icon {
  position: absolute;
  right: 10px; /* Abstand von der rechten Seite */
  top: 50%; /* Zentriert in der Höhe */
  transform: translateY(-50%);
  font-size: 16px; /* Falls für das Icon relevant */
  color: #aaa;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Abstand zwischen den Filter-Elementen */
  justify-content: center;
  width: 100%; /* Sicherstellen, dass die Filter ebenfalls zentriert sind */
}

.spark-section-2 {
  width: 100%;
  padding: 176px 64px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
  position: relative;
}

.spark-section-2.spark-dark-hero-background-image {
  color: white;
  background-image: linear-gradient(74.2deg, #00aa96, rgba(0, 0, 0, 0) 100%);
  background-position: 0 0, 50% 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  height: 40vh;
  font-family: New rubrik edge, sans-serif;
  display: flex;
}

.spark-container-2 {
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.filters {
  display: flex;
  flex-wrap: wrap; /* Erlaubt das Umbruch der Elemente */
  gap: 10px; /* Abstand zwischen den Filter-Elementen */
  justify-content: center; /* Zentriert die Filter, wenn weniger Platz vorhanden ist */
}

.filter-dropdown {
  padding: 5px; /* Fügt Padding innerhalb des Dropdowns für bessere Lesbarkeit hinzu */
  min-width: 120px; /* Mindestbreite für jedes Dropdown, um sicherzustellen, dass Inhalte sichtbar sind */
  flex: 1 1 auto; /* Ermöglicht den Dropdowns, sich innerhalb des verfügbaren Raums auszudehnen */
}

@media (max-width: 600px) {
  /* Stiländerungen für Bildschirme kleiner als 600px */
  .filters {
    flex-direction: column; /* Stapelt die Filter-Elemente untereinander */
  }

  .filter-dropdown {
    width: 100%; /* Lässt die Dropdowns die volle Breite einnehmen */
  }
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* oder 'absolute', abhängig vom Kontext */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 255, 255); /* leicht transparenter Hintergrund */
  z-index: 10; /* Stellt sicher, dass der Spinner über anderen Inhalten liegt */
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #a4d7d4;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is-hidden {
  display: none;
}
</style>
